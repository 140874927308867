import { graphql } from 'gatsby'
import React from 'react'
import IndexLayout from 'site/src/components/org.layout/layout.component'
import { ArticleNewsPageData, NewsArticlesDetailPage } from 'site/src/components/org.news-article-detail-page/news-articles-detail-page.component'
import { CosmicjsArtigosCientificosMetadataEspecialidades, CosmicjsArtigosCientificosMetadataTags, Query } from 'site/src/data/graphql/graphql-types'
import { parseToDate } from 'site/src/utils/date-parser/date-parser'
import { PageProps } from 'site/src/utils/local-types'
import { appPaths, getCanonicalUrl } from 'utils/path'
import { isDoctorUrl, normalizeUrl } from 'utils/url'

export interface ScientificArticlesDetailTemplateProps {
  slug: string
  last4Articles: {
    title: string
    slug: string
    description: string
    publishedDate: string
    id: string
  }[]
}

const ScientificArticlesDetailTemplate: React.FunctionComponent<PageProps<ScientificArticlesDetailTemplateProps, Query>> = props => {
  const data = props.data.cosmicjsArtigosCientificos

  const currentPath = `${props.location.pathname}${props.location.search}`
  const siteUrl = props.data.site.siteMetadata.siteUrl
  const currentPageUrl = normalizeUrl(`${siteUrl}${currentPath}`)

  const lastArticles = props.pageContext.last4Articles.filter(item => item.id !== data.id)

  const sortTags = (especialidades: CosmicjsArtigosCientificosMetadataEspecialidades[], tags: CosmicjsArtigosCientificosMetadataTags[]) => {
    const specialtyList = (especialidades || []).map(tag => tag.title)
    const tagsList = (tags || []).map(tag => tag.title)
    return specialtyList.concat(tagsList).sort()
  }

  const pageData: ArticleNewsPageData = {
    title: data.title,
    description: data.metadata.descricaoSimples,
    publishedDate: parseToDate(data.metadata.dataPublicacao),
    htmlContent: data.content,
    previousContentData: lastArticles,
    tags: data.metadata.especialidades && data.metadata.especialidades.map(tag => tag.title),
    seo: {
      title: data.metadata.seo.titulo,
      imageUrl: data.metadata.seo.imagem.url,
      imageAlt: data.metadata.seo.imagemAlternativa,
      description: data.metadata.seo.descricao,
      article: {
        published_time: parseToDate(data.metadata.dataPublicacao),
        modified_time: parseToDate(data.modified_at),
        autor: ['Fleury, medicina e saúde'].concat(data.metadata.autor),
        section: 'Artigos',
        tag: sortTags(data.metadata.especialidades, data.metadata.tags)
      }
    }
  }
  return (
    <IndexLayout location={props.location}>
      <NewsArticlesDetailPage
        data={pageData}
        slug={props.pageContext.slug}
        currentPageUrl={currentPageUrl}
        isDoctor={isDoctorUrl(props.location.pathname)}
        canonicalUrl={getCanonicalUrl(siteUrl, appPaths.scientificArticles.path, props.pageContext.slug)}
        staticInfo={{
          col2Title: props.data.cosmicjsInformacoesEstaticas.metadata.col2Titulo,
          col2ButtonTitle: props.data.cosmicjsInformacoesEstaticas.metadata.col2BotaoTexto,
          col2ButtonTo: appPaths.scientificArticles.path.doctorUrl,
          col2ReadMoreButton: props.data.cosmicjsInformacoesEstaticas.metadata.col2BotaoLerDetalheTexto,
          section: 'Artigos'
        }}
      />
    </IndexLayout>
  )
}

export default ScientificArticlesDetailTemplate

export const query = graphql`
  query ScientificArticlesDetailBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    cosmicjsArtigosCientificos(slug: { eq: $slug }) {
      id
      title
      modified_at
      published_at
      content
      metadata {
        descricaoSimples
        dataPublicacao
        especialidades {
          title
        }
        tags {
          title
        }
        especialistas {
          slug
          title
          type_slug
          metadata {
            foto {
              url
              imgix_url
            }
          }
        }
        autor
        seo {
          titulo
          descricao
          imagem {
            url
          }
          imagemAlternativa
        }
      }
    }
    cosmicjsInformacoesEstaticas(slug: { eq: "artigos-cientificos" }) {
      metadata {
        col2Titulo
        col2BotaoTexto
        col2BotaoLerDetalheTexto
      }
    }
  }
`
